import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { UserActivityType } from 'config'
import { IUserActivity } from 'pages/Admin/UserActivity/type'
import { useEffect, useState } from 'react'
import { filterBorrowerActivities, filterUserActivities } from 'services'
import { formatTime, openUserActivityDlg } from 'utils'

export const RecentActivities = ({ userId, isBorrower = false }: { userId: number; isBorrower?: boolean }) => {
  const [activities, setActivities] = useState<Array<IUserActivity>>([])
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {
    setLoading(true)
    const activityFunc = isBorrower ? filterBorrowerActivities : filterUserActivities
    activityFunc(userId, {
      startDate: '',
      endDate: '',
      count: 20,
      skip: activities.length,
    })
      .then(({ data, total }) => {
        const newActivities = cloneDeep(activities)
        newActivities.push(...data)
        setActivities(newActivities)
        setTotal(Number(total))
      })
      .finally(() => setLoading(false))
  }

  const onSelectActivity = (activity: IUserActivity) => {
    if (
      [UserActivityType.LOGIN, UserActivityType.FORGET_PASSWORD, UserActivityType.RESET_PASSWORD].indexOf(
        activity.type,
      ) !== -1
    )
      return
    openUserActivityDlg(activity)
  }

  return (
    <div>
      <LayoutLoading show={isLoading} />

      {activities.map((item, index) => (
        <div key={`activity-${item.id}`} className="mb-2 border-b pb-2 text-sm">
          <p>
            {index + 1}. {item.action}{' '}
            {item.loanNumber ? <span className="font-semibold">({item.loanNumber})</span> : ''}
          </p>
          <div className="flex justify-between">
            <p className="text-gray-500">{formatTime(item.createdAt)}</p>
            {Object.keys(item.detail).length > 0 ? (
              <span
                className="cursor-pointer hover:underline text-shade-blue text-xs"
                onClick={() => onSelectActivity(item)}
              >
                Details
              </span>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      ))}

      {total > activities.length && (
        <span className="cursor-pointer hover:underline text-shade-blue text-xs" onClick={() => fetch()}>
          Load More
        </span>
      )}
    </div>
  )
}
