import {
  DocumentPlusIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  KeyIcon,
  LinkIcon,
  PencilIcon,
  PencilSquareIcon,
  SquaresPlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { ChangePassword } from 'components/Modals'
import { companyName, InputType, itemCountPerPage } from 'config'
import { UserNotesSection } from 'pages/ManageAccounts/UserNotesSection'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { filterBorrowers, getBorrowerLink, getBorrowerLoans, sendBorrowerEmail, updateBorrower } from 'services'
import { svgLoading, svgSearch } from 'stories/assets'
import { Button, Input, Pagination, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  capitalizeFirstLetter,
  confirm,
  formatDate,
  formatTime,
  InputConvert,
  InputValidate,
  prompt,
  renderHeader,
  useTitle,
} from 'utils'
import { copyClipboard } from 'utils/copyClipboard'
import { confirmOptions } from 'utils/modals/confirmOptions'
import { RenderInput } from 'utils/RenderInput'

import { collapseInputs } from './constants'
import { EditBorrowerModal } from './EditBorrowerModal'
import { type IBorrower, BorrowerStatus } from './types'

const headers = [
  {
    title: 'No',
    key: 'no',
  },
  {
    title: 'Name',
    key: 'fullName',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Phone',
    key: 'phone',
  },
  {
    title: 'Address',
    key: 'presentAddress',
  },
  {
    title: 'Created At',
    key: 'createdAt',
  },
]

export const ManageBorrowers = () => {
  useTitle(`Borrowers - ${companyName}`)

  const [users, setUsers] = useState<Array<IBorrower>>([])
  const [total, setTotal] = useState(0)
  const [action, setAction] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [inputs, setInputs] = useState<Record<string, InputType>>(collapseInputs())
  const [borrowerLoans, setBorrowerLoans] = useState<Array<Record<string, string>>>([])
  const [isOpenUserNotes, setIsOpenUserNotes] = useState(false)
  const [isOpenFiles, setIsOpenFiles] = useState(false)

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: -1,
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  const [editUser, setEditUser] = useState<IBorrower | null>()
  const [changePwdUser, setChangePwdUser] = useState<IBorrower | null>(null)

  useEffect(() => {
    filterUsersData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterUsersData = async (filters: Record<string, any>) => {
    setAction('getUsers')
    const { data, total } = await filterBorrowers({
      query: filters.query.trim(),
      count: itemCountPerPage,
      skip: filters.pageNum * itemCountPerPage,
      orderBy: filters.orderBy,
      orderDir: filters.orderDir,
    })
    setUsers(data)
    setTotal(total)
    setAction('')
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterUsersData(newFilters)
  }

  const onSort = (key: string, order: 1 | -1) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${order}`
    setFilters(newFilters)
    filterUsersData(newFilters)
  }

  const onEdit = (borrower: IBorrower) => {
    setEditUser(borrower)
  }

  const onCopyLink = async (borrower: IBorrower) => {
    setAction('getBorrowerLink')
    const link = await getBorrowerLink(borrower.id)
    copyClipboard(link)
    setAction('')
  }

  const onSendEmail = async (borrower: IBorrower) => {
    setAction('sendBorrowerEmail')
    await sendBorrowerEmail(borrower.id)
    setAction('')
  }

  const onResetPassword = async (borrower: IBorrower) => {
    setChangePwdUser(borrower)
    // const result = await confirm(`Are you sure you want to reset password - "${borrower.fullName}"?`)
    // if (!result) return

    // setAction('resetPassword')
    // await resetBorrowerPassword(borrower.id)
    // setAction('')
  }

  const onChangeStatus = (id: number, status: BorrowerStatus) => {
    console.log(id, status)
  }

  const onShowFiles = (index: number, borrowers: any) => {
    setAction('fetching')
    setSelectedIndex(index)
    setIsOpenUserNotes(false)
    setIsOpenFiles(true)
    getBorrowerLoans(borrowers[index].id).then((loans: any) => {
      setBorrowerLoans(loans)
    })
    const newInputs: Record<string, any> = {}
    ;['general', ...borrowers[index].entities].map((key) => {
      newInputs[key] = collapseInputs().general
      newInputs[key].filePath = `borrower/${borrowers[index].no}`
      newInputs[key].title = `${capitalizeFirstLetter(key)} Files`
      newInputs[key]['entity'] = key
      const documents = (borrowers[index] as any)['documents'] || []
      newInputs[key].value = documents.filter((item: any) => item.section == key)
    })
    setInputs(newInputs)
    setAction('')
  }

  const onChangeFiles = async (key: string, value: string, borrower: any, index: number) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)

    const data: Record<string, any> = {}
    data['documents'] = []
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      newInputs[key].value.map((item: any) => {
        item.section = key
      })
      data['documents'].push(...newInputs[key].value)
    }
    data['email'] = borrower.email

    setAction('submit')
    await updateBorrower(borrower.id, data)
    const temp = cloneDeep(users)
    temp[index].documents = data['documents']
    setUsers(temp)
    setAction('')
  }

  const onCreateEntity = async (borrower: any, index: number) => {
    let title = await prompt('Add New Entity', {
      placeholder: 'New Entity Title',
    })
    title = title.trim()
    if (borrower.entities.map((v: string) => v.toLowerCase()).includes(title.toLowerCase())) {
      toast('Entity already exists.', { type: 'error' })
      return
    }
    const data: Record<string, any> = {}
    data['email'] = borrower.email
    data['entities'] = [...borrower.entities]
    data['entities'].push(title)
    setAction('submit')
    await updateBorrower(borrower.id, data)
    const temp = cloneDeep(users)
    temp[index].entities.push(title)
    setUsers(temp)
    onShowFiles(index, temp)
    setAction('')
  }

  const onRemoveEntity = async (entity: string, index: number) => {
    const result = await confirm(`Are you sure you want to remove "${entity}" entity?`)
    if (!result) return
    await updateEntity(entity, index)
  }

  const onEditEntity = async (entity: string, index: number) => {
    const newTitle: any = await prompt('Edit Entity Title', {
      value: entity,
    })
    if (newTitle === false) return
    await updateEntity(entity, index, newTitle)
  }

  const updateEntity = async (oldEntity: string, index: number, newEntity?: string) => {
    setAction('submit')
    const newEntities = users[index].entities.filter((value: string) => value != oldEntity)
    if (newEntity) newEntities.push(newEntity)
    const newDocuments: Array<any> = []
    users[index].documents.map((item) => {
      if (item.section == oldEntity) {
        if (newEntity) item.section = newEntity
        else return
      }
      newDocuments.push(item)
    })
    const data = {
      email: users[index].email,
      entities: newEntities,
      documents: newDocuments,
    }
    await updateBorrower(users[index].id, data)
    const temp = cloneDeep(users)
    temp[index].entities = newEntities
    temp[index].documents = newDocuments
    setUsers(temp)
    onShowFiles(index, temp)
    setAction('')
  }

  const onCreateLoan = async (id: number) => {
    const options: any[] = []
    console.log(users[id].entities)
    users[id].entities.map((v: string, index: number) => {
      options.push({ name: capitalizeFirstLetter(v), value: index })
    })
    options.push({ name: users[id].fullName, value: -1 })
    const result: any = await confirmOptions('Choose Entity to Create a Loan', options)
    if (result === false) return
    window.open(`/loan_process/structure/new?borrowerNo=${users[id].no}&entity=${result}`)
  }

  const onAddNotes = async (index: number) => {
    setSelectedIndex(index)
    setIsOpenFiles(false)
    setIsOpenUserNotes(true)
  }

  return (
    <div className="registration-container py-6 px-2">
      <div className="shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Manage Borrowers</span>{' '}
            <span className="text-base ml-3">
              {action !== '' ? (
                <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
              ) : (
                `(${total})`
              )}
            </span>
          </h1>
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="w-80">
            <Input
              type="search"
              title="Search Accounts"
              hasIcon
              icon={svgSearch}
              value={filters.query}
              onChange={(value) => onChangeFilter('query', value)}
            />
          </div>
          <Button onClick={() => setEditUser(null)}>Create Borrower</Button>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={!!action} />

          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="pl-6 py-3 pr-3">
                  No
                </th>
                {headers.map(({ title, key }, index) =>
                  renderHeader({
                    title,
                    sortable: true,
                    key,
                    sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                    onSort,
                    index,
                  }),
                )}
                <th scope="col" className="py-3 px-3">
                  Actions
                </th>
                <th scope="col" className="py-3 px-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <>
                  <tr
                    className={`${
                      index % 2 ? 'bg-gray-50' : ''
                    } bg-white hover:bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700`}
                    key={`${index}`}
                  >
                    <td
                      scope="row"
                      className="pl-6 py-3 pr-3 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      {filters.pageNum * itemCountPerPage + index + 1}
                    </td>

                    <td className="py-3 px-2">{user.no}</td>

                    <td className="py-3 px-2">
                      <Link
                        to={`/borrowers/${user.no}`}
                        target="_blank"
                        className="font-bold text-shade-blue cursor-pointer border-b w-fit mb-2 text-left"
                      >
                        {user.fullName}
                      </Link>
                    </td>

                    <td className="py-3 px-2">{user.email}</td>

                    <td className="py-3 px-2">{user.phone}</td>

                    <td className="py-3 px-2">{user.presentAddress}</td>

                    <td className="py-3 px-2">{formatTime(user.createdAt)}</td>

                    <td className="py-3 px-2">
                      <div className="flex">
                        <Tooltip message="Edit Borrower">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onEdit(user)}
                          >
                            <PencilSquareIcon className="w-4 h-4" />
                          </span>
                        </Tooltip>
                        <Tooltip message="Reset Password">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onResetPassword(user)}
                          >
                            <KeyIcon className="w-4 h-4" />
                          </span>
                        </Tooltip>
                        <Tooltip message="Copy Borrower Link">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onCopyLink(user)}
                          >
                            <LinkIcon className="w-4 h-4" />
                          </span>
                        </Tooltip>
                        <Tooltip message="Send Email">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onSendEmail(user)}
                          >
                            <EnvelopeIcon className="w-4 h-4" />
                          </span>
                        </Tooltip>
                        <Tooltip message="Documents">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onShowFiles(index, users)}
                          >
                            <DocumentTextIcon className="w-4 h-4" />
                          </span>
                        </Tooltip>
                        <Tooltip message="Write Notes">
                          <span
                            className="text-blue-600 p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onAddNotes(index)}
                          >
                            <PencilIcon className="w-4 h-4"></PencilIcon>
                          </span>
                        </Tooltip>
                      </div>
                    </td>

                    <td className="py-3 px-2">
                      <div className="scale-90">
                        <Toggle
                          id={`status-${user.id}`}
                          key={`status-${user.id}`}
                          value={user.status == BorrowerStatus.Active}
                          onChange={(checked) =>
                            onChangeStatus(user.id, checked ? BorrowerStatus.Active : BorrowerStatus.Inactive)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  {selectedIndex == index && isOpenFiles && (
                    <tr className={`border-b`} key={`borrowerFile-${index}`}>
                      <td></td>
                      <td colSpan={7} className="px-2 py-4">
                        <div className="flex items-center justify-end">
                          <span
                            className="text-[14px] text-red-800 hover:underline cursor-pointer"
                            onClick={() => {
                              setSelectedIndex(-1), setIsOpenFiles(false)
                            }}
                          >
                            Close
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="font-bold text-lg">Loans</span>
                          <span
                            className="font-bold flex items-center gap-1 text-shade-blue hover:underline cursor-pointer pl-3 py-3"
                            onClick={() => onCreateLoan(index)}
                          >
                            Create Loan
                            <DocumentPlusIcon className="w-4 h-4" />
                          </span>
                        </div>
                        <table className={`w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 mb-4`}>
                          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              {[
                                'Loan #',
                                'Property Address',
                                'Borrower Name',
                                'Entity Title',
                                'Loan Status',
                                'Servicing Status',
                                'Broker',
                              ].map((item, index) => (
                                <th scope="col" className={`px-4 py-2 border`} key={index}>
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {borrowerLoans.map((item: any, index: number) => {
                              return (
                                <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                                  <td className="border px-4 py-1 text-shade-blue hover:underline cursor-pointer">
                                    <Link to={`/loan_process/overview/${item.no}`} target="_blank">
                                      {item.byteproFileName || item.no}
                                    </Link>
                                  </td>
                                  <td className="border px-4 py-1">{item.subjectPropertyAddress}</td>
                                  <td className="border px-4 py-1">
                                    {item.borrowerFirstName} {item.borrowerLastName}
                                  </td>
                                  <td className="border px-4 py-1">{item.entityTitle}</td>
                                  <td className="border px-4 py-1">
                                    {item.loanstatus}
                                    <br />
                                    <span className="italic">{formatDate(item.asOf)}</span>
                                  </td>
                                  <td className="border px-4 py-1">
                                    {item.servicingStatus}
                                    <br />
                                    <span className="italic">{formatDate(item.servicingAsOf)}</span>
                                  </td>
                                  <td className="border px-4 py-1">{item.brokerCompany}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <div className="flex items-center justify-end">
                          <span
                            className="font-bold flex items-center gap-1 text-shade-blue hover:underline cursor-pointer pl-3 pt-3"
                            onClick={() => onCreateEntity(user, index)}
                          >
                            Create Entity
                            <SquaresPlusIcon className="w-4 h-4" />
                          </span>
                        </div>
                        {Object.keys(inputs).map((key) => {
                          const input: any = inputs[key]
                          if (input.entity != 'general')
                            input.additionalActions = [
                              <span
                                className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded"
                                onClick={() => onRemoveEntity(input.entity, index)}
                              >
                                <TrashIcon className="w-4 h-4" />
                              </span>,
                              <span
                                className="text-blue-800 p-1 hover-shadow1 cursor-pointer rounded"
                                onClick={() => onEditEntity(input.entity, index)}
                              >
                                <PencilSquareIcon className="w-4 h-4" />
                              </span>,
                            ]
                          return (
                            <div className="py-2">
                              <RenderInput
                                Key={key}
                                input={input}
                                onChange={(key: string, value: any) => onChangeFiles(key, value, user, index)}
                              />
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  )}
                  {selectedIndex === index && isOpenUserNotes && (
                    <tr className={`border-b`} key={`childBroker-${index}`}>
                      <td></td>
                      <td colSpan={8} className="px-2">
                        <UserNotesSection
                          user={users[selectedIndex]}
                          onClose={() => {
                            setIsOpenUserNotes(false)
                            setSelectedIndex(-1)
                          }}
                          isBorrower
                        />
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-wrap justify-end items-center">
          <div className="flex justify-end items-center mt-3 mb-3">
            <Pagination
              totalCount={total}
              itemCountPerPage={itemCountPerPage}
              onNavigate={(num: number) => onChangeFilter('pageNum', num)}
              pageNum={filters.pageNum}
            />
          </div>
        </div>

        {editUser !== undefined && (
          <EditBorrowerModal
            data={editUser}
            onClose={(result: boolean) => {
              setEditUser(undefined)
              result && filterUsersData(filters)
            }}
          />
        )}

        {changePwdUser && (
          <ChangePassword
            userId={changePwdUser.id}
            email={changePwdUser.email}
            isOpen
            isBorrower
            lastUpdatedAt={Date.now()}
            onClose={() => setChangePwdUser(null)}
          />
        )}
      </div>
    </div>
  )
}
