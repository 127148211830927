import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { companyName } from 'config'
import { RecentActivities } from 'pages/ManageAccounts/AccountDetails/RecentActivities'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getBorrowerByNo } from 'services'
import { useTitle } from 'utils'

import { EditBorrowerModal } from './EditBorrowerModal'

export const BorrowerDetails = () => {
  useTitle(`Borrower Details - ${companyName}`)
  const navigate = useHistory()
  const urlParams: any = useParams()
  const { borrowerId = 0 } = urlParams

  const [isLoading, setLoading] = useState(true)
  const [borrower, setBorrower] = useState(null)

  const initData = async () => {
    setLoading(true)
    const borrowerData = await getBorrowerByNo(borrowerId)
    setBorrower(borrowerData)
    setLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <div className="registration-container py-6 px-2">
      <div className="max-w-screen-2xl m-auto bg-white sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="grid lg:grid-cols-4 gap-8">
          <div className="col-span-3 shadow1 rounded p-3 md:p-7">
            <div className="text-shade-blue flex items-center mb-2 font-bold">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              <span className="ml-1 cursor-pointer hover:underline" onClick={() => navigate.push('/borrowers')}>
                Back to Manage Borrowers
              </span>
            </div>

            <p className="text-lg mb-4 font-semibold">Borrower Details</p>
            {borrower && <EditBorrowerModal data={borrower} onClose={() => {}} isModal={false} />}
          </div>

          <div className="shadow1 rounded p-3 md:p-7 max-h-[100vh] overflow-y-auto">
            <p className="text-lg mb-4 font-semibold">Recent Activities</p>
            {borrower && <RecentActivities userId={borrower['id']} isBorrower={true} />}
          </div>
        </div>
      </div>
    </div>
  )
}
