import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { createBorrower, updateBorrower } from 'services'
import { CopyableText, Modal } from 'stories/components'
import { generateRandomPassword, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import type { IBorrower } from './types'

export const EditBorrowerModal = ({
  data: borrower,
  onClose,
  isModal = true,
}: {
  data: IBorrower | null
  onClose: Function
  isModal?: boolean
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    let newInputs: any = cloneDeep(defaultInputs())
    if (!borrower) {
      newInputs['orgPassword'].value = generateRandomPassword()
      setInputs(newInputs)
      return
    }
    Object.keys(newInputs).map((key) => {
      newInputs[key].value = (borrower as any)[key] || ''
    })
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: string) => {
    if (!isModal) return
    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }

    if (hasError) {
      setInputs(newInputs)
      return
    }

    delete data.no

    setLoading(true)

    let promise
    if (borrower) promise = updateBorrower(borrower.id, data)
    else promise = createBorrower(data)

    promise.then(() => onClose(true)).finally(() => setLoading(false))
  }

  const borrowerForm = () => {
    return (
      <div className={`${isModal ? 'w-196' : 'w-full'} grid grid-cols-2 gap-4`}>
        {Object.keys(inputs).map((key) => {
          const input = inputs[key]

          if (!isModal && input.inputType == 'map') input.inputType = 'text'

          if (key == 'orgPassword')
            return (
              <div
                className={`col-span-${input.span || 1} pt-2 ${
                  borrower?.requireReset ? 'text-shade-blue font-bold' : 'text-gray-400'
                }`}
              >
                <p className={`text-xs text-gray-700 font-normal`}>Original Password</p>
                <CopyableText copyText={input.value}>{input.value}</CopyableText>
              </div>
            )

          return (
            <div className={`col-span-${input.span || 1}`}>
              <RenderInput Key={key} input={input} onChange={onChange} />
            </div>
          )
        })}
      </div>
    )
  }

  return isModal ? (
    <Modal
      isOpen
      title={`${borrower ? 'Edit' : 'Create'} Borrower`}
      loading={isLoading}
      titleOkay="Submit"
      onOk={onSubmit}
      onClose={() => onClose(false)}
    >
      {borrowerForm()}
    </Modal>
  ) : (
    borrowerForm()
  )
}
